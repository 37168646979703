define("discourse/plugins/Discourse Revive/discourse/components/revive-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    visible: true,
    slot: null,
    init: function init() {
      this._super();

      var siteSettings = this.siteSettings;
      var slot = this.get('slot').trim();
      var position = slot.replace('_mobile', '');
      this.set('revivehost', (siteSettings.revive_adserver_host || '').trim());
      this.set('slot', slot);
      var userSee = !this.currentUser || this.currentUser.get('trust_level') <= siteSettings.revive_through_trust_level;

      if (userSee && siteSettings["revive_show_".concat(position)]) {
        var zoneid = Discourse.SiteSettings['revive_' + slot + '_zone_id'];

        if (isNaN(parseFloat(zoneid)) || !isFinite(zoneid)) {
          return;
        }

        this.set('visible', true);
        this.set('zoneid', zoneid);

        var _this$getProperties = this.getProperties('width', 'height'),
            width = _this$getProperties.width,
            height = _this$getProperties.height;

        this.set('width', width);
        this.set('height', height);
        this.set('rnd', Math.floor(Math.random() * 100000 + 1));
      }
    }
  });

  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/components/revive-block"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"visible\"]]],null,{\"statements\":[[0,\"  \"],[7,\"iframe\",true],[11,\"class\",[29,[\"revive-ad \",[28,\"unbound\",[[24,[\"slot\"]]],null]]]],[10,\"id\",\"af19849f\"],[10,\"name\",\"af19849f\"],[11,\"src\",[29,[\"//\",[28,\"unbound\",[[24,[\"revivehost\"]]],null],\"/www/delivery/afr.php?zoneid=\",[28,\"unbound\",[[24,[\"zoneid\"]]],null],\"&cb=\",[28,\"unbound\",[[24,[\"rnd\"]]],null]]]],[10,\"frameborder\",\"0\"],[10,\"scrolling\",\"no\"],[11,\"width\",[29,[[28,\"unbound\",[[24,[\"width\"]]],null]]]],[11,\"height\",[29,[[28,\"unbound\",[[24,[\"height\"]]],null]]]],[8],[0,\"\\n        \"],[7,\"a\",true],[11,\"href\",[29,[\"//\",[28,\"unbound\",[[24,[\"revivehost\"]]],null],\"/www/delivery/ck.php?n=abc1aa1e&cb=\",[28,\"unbound\",[[24,[\"rnd\"]]],null]]]],[10,\"target\",\"_blank\"],[8],[7,\"img\",true],[11,\"src\",[29,[\"//\",[28,\"unbound\",[[24,[\"revivehost\"]]],null],\"/www/delivery/avw.php?zoneid=\",[28,\"unbound\",[[24,[\"zoneid\"]]],null],\"&cb=\",[28,\"unbound\",[[24,[\"rnd\"]]],null],\"&n=abc1aa1e\"]]],[10,\"border\",\"0\"],[10,\"alt\",\"\"],[8],[9],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/components/revive-block"}});
Ember.TEMPLATES["javascripts/connectors/discovery-list-container-top/revive"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"site\",\"mobileView\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"revive-block\",null,[[\"width\",\"height\",\"slot\"],[\"320\",\"50\",\"topiclist_top_mobile\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"revive-block\",null,[[\"width\",\"height\",\"slot\"],[\"728\",\"90\",\"topiclist_top\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/connectors/discovery-list-container-top/revive"}});
Ember.TEMPLATES["javascripts/connectors/topic-above-post-stream/revive"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"site\",\"mobileView\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"revive-block\",null,[[\"width\",\"height\",\"slot\"],[\"320\",\"50\",\"topic_top_mobile\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"revive-block\",null,[[\"width\",\"height\",\"slot\"],[\"728\",\"90\",\"topic_top\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/connectors/topic-above-post-stream/revive"}});
Ember.TEMPLATES["javascripts/connectors/topic-above-suggested/revive"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"site\",\"mobileView\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"revive-block\",null,[[\"width\",\"height\",\"slot\"],[\"320\",\"50\",\"topic_bottom_mobile\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"revive-block\",null,[[\"width\",\"height\",\"slot\"],[\"728\",\"90\",\"topic_bottom\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/connectors/topic-above-suggested/revive"}});

